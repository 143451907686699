import React from 'react';
import '../../App.css';
import '../Cards.css';
import CardItemSenzaTxt from '../CardItemSenzaTxt';
import Footer from '../Footer';


function ABB() {
   return (
  <>
    <div className='cards'>
      <h1>Worked for ABB in the project ADMA-OPCO / Al Nasr oil field 
       </h1>
    <a style={{display: 'flex', justifyContent: 'center'}}  href='http://u1701397.plsk.regruhosting.ru/case-studies/adma-opco-al-nasr-oil-field/' target="_blank"rel="noreferrer">more info </a>
       
      <div className='cards__container'>
        <div className='cards__wrapper'>
       <ul className='cards__items'>
           <CardItemSenzaTxt
              src='images/img-abb.jpeg'
              text=''
              label=''
              path='/ABB'
            />            
            <CardItemSenzaTxt
              src='images/ABB_letter.jpg'
              text=''
              label=''
              path='/ABB'
            />   
          </ul>
<h1>If you are interested to talk about it, just <a href='/contactme' target="_blank"rel="noreferrer">contact me!</a></h1><br></br>
          <ul className='cards__items'>
         
           <CardItemSenzaTxt
              src='images/chopper.png'
              text=''
              label=''
              path='/ABB'
            />    
         <CardItemSenzaTxt
              src='images/office1.png'
              text=''
              label=''
              path='/ABB'
            />   
          </ul>
        </div>
      </div>  </div>
       <Footer />
    </>

  );

}
export default  ABB;