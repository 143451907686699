import React from 'react';
import '../../App.css';
import { Link } from 'react-router-dom';
import Footer from '../Footer';

export default function CV() {
  return (
          <div className='cv'>
      <div className='me-image-box-CV'>
           <img src='/images/me.jpg'  alt='salvo' />  
      </div>
          <div id="content">
          <div className="curriculum"> <h2>Curriculum Vitae</h2>
          <div className="personal-infos">
          <p className="personal-info">
          <i className="fas fa-map-marker-alt"></i>
          Based in <b>St Petersburg</b>, RU</p><p className="personal-info">
          <i className="fas fa-pizza-slice"></i> From <b>Catania</b>, Italy</p>
          <p className="personal-info">
          <i className="fas fa-address-card"></i> Italian and Russian Citizen</p>
          <p className="personal-info">
          <i className="fas fa-language"></i> Spoken languages: English, Russian and Italian.</p>
          </div>
          <div className="cv-block summary">
          <h3>Summary</h3>
<hr></hr>
          <p>All-round, goal-oriented, full-stack software engineer, mainly focused on the integration of software with hardware and devices, web apps, and front-end applications for Windows or cross-platform.
          Managed different projects like an IT project manager and in many others played the role like Technical Support Engineer.<br></br>Last almost year and a half I worked in the position  <a target="_blank" rel="noreferrer" href="https://spb.hh.ru/resume/c44a448fff02357a580039ed1f5571456e4b6f"> CTO and Head of Research & Development </a> from Company that do software for aviation and not only.</p>
          <p>
              <i>Specialties</i>
          </p>
          <p>Software and manage projects: C#, .Net, React JS, Electron, Core, WPF, REST APIs, QT, C++, Delphi, Razor/MVC, PHP, JavaScript ES 6, jQuery, Figma.</p>
          <p>Key Skills and Protocol knowledge: Modbus RTU/TCP, RS232, RS422, RS485, CAN, I2C, NMEA0183, TCP/UDP, Virtualization, SVN, GitLab, Google API, etc.</p>
          <p>Database: Oracle, MySQL, SQLite, PostgreSQL.</p>          
          <p>Strong aptitude/experience in integration hardware and devices, working independently, delivering end-to-end solutions. I am a person that enjoys finding a solution at the problem and resolve it in the excellent way.
             I know how to work with someone else's code, and I enjoy doing it, I like to think what was done when it was created.</p>
          <p></p>          
          </div>
          <div className="cv-block work-experiences">
          <h3>Work Experience</h3>
 <hr></hr>
 <div className="cv-internal-block work-experience">
          <h4>CTO and Head of Research and Development at <i>Peleng JSC</i> (<a target="_blank" rel="noreferrer" href="https/peleng.ru/">www.peleng.ru</a>)</h4>
          <p className="timeframe">September  2022 – currently</p>
          <p>Development projects and manage team<br></br>               
             - Planning and Task (Asana / Trello) <br></br>
             - Code Review and GitLab control <br></br>
             - ISO 9001-2015<br></br>
             - Project Management<br></br>
             - Technical requirement documentation<br></br>
             - Management team: 3 developers, 3 product owner, 1 engineer builder, 4 engineer<br></br>
             - Improved and managed Factory Accepted Test (FAT) and Site Accepted Test (SAT) in Company and Tagikistan<br></br>
              - Deciding which areas are worthy of future research<br></br>
              - Starting new projects<br></br>
              - Overseeing projects<br></br>
              - Attending events and conferences<br></br>
              - Involving and integrating new technology in company<br></br>
             - Improve the team and interview with new developer and engineer <br></br>
             - Develop and code software, script Oracle, .bat code<br></br>  <br></br>          
                 <a target="_blank" rel="noreferrer" href="http://peleng.ru/eng/products/catalog">Projects</a>
          </p>
          </div>
          <hr></hr>
          <div className="cv-internal-block work-experience">
          <h4>Software engineer at <i>Sea Surveillance AS.</i> (<a target="_blank" rel="noreferrer" href="http://u1701397.plsk.regruhosting.ru/">www.seasurv.net</a>)</h4>
          <p className="timeframe">June 2018 – April 2022</p>
          <p>Development Software and projects <br></br> 
              Used Visual Studio 2012/2013/2017  C# / React JS / php / python  <br></br>
             - HMS "Helicopter Monitor System" - OffShore System CAP437 <a target="_blank" rel="noreferrer" href="http://u1701397.plsk.regruhosting.ru/products/seah-helideck-monitoring-system/">pictures and more info</a> <br></br>
             - Weather Station UI using Sensors from  - <a target="_blank" rel="noreferrer" href="http://www.vaisala.com">Vaisala</a><br></br>
             - <a target="_blank" rel="noreferrer" href="http://u1701397.plsk.regruhosting.ru/products/radar-dome/">Radome UI</a> - Radar System with different Radar [Furuno / Sperry Radar Control]<br></br>
             - UI for Recording VHF with Google Cloud and API integration <a target="_blank" rel="noreferrer" href="http://u1701397.plsk.regruhosting.ru/products/vhf-radio-system/">pictures and more info</a><br></br>
             - Integration of Token for security  by Sentinel - <a target="_blank" rel="noreferrer" href="https://cpl.thalesgroup.com">Thales Group</a><br></br>
             - installation of Project in OffShore for client <a target="_blank" rel="noreferrer" href="https://www.adnoc.ae">ADNOC</a>  in  AL NASR  (Abu Dhabi)<br></br>
             - Consultancy Project Offshore in Abu Dhabi for <a target="_blank" rel="noreferrer" href="https://ricevuto.ru/ABB">ABB</a> - https://new.abb.com/ <br></br>
                  <Link to='/projects'>Projects</Link><br></br>
          </p>
          <p>
              <i>Certification</i>
          </p>
          <ul>
                <li> <a target="_blank" rel="noreferrer" 
         href="https://opito.com/standards/basic-offshore-safety-induction-and-emergency-training-bosiet-with-compressed-air-emergency-breathing-system-ca-ebs">
          BOSIET Certificate</a> </li>
                <li> Certified for yellow fever - <a target="_blank" rel="noreferrer" href="images/SalvoYellowFeverCertification.jpeg">link</a></li>
         </ul>
          </div>
          <hr></hr>
          <div className="cv-internal-block work-experience">
          <h4>Online Event Marketing Manager Specialist & Video Content at 
          <i> SEMrush</i> (
          <a target="_blank" rel="noreferrer" href="http://www.semrush.com">www.semrush.com</a>)</h4>
          <p className="timeframe">May 2016 – November 2017</p>
            <p>
              <a target="_blank" rel="noreferrer" href="https://it.semrush.com/user/145832673/">First work experience in Russia to learn language!</a> <br></br>
              They were searching for Italian expert in Informatic and Web, I was super happy to join company with more than 400 people.
              <br></br>- Negotiated with SEO, SEM, SMM and Web Marketing experts to invite them to participate in Online and Offline Events<br></br>
             - Organize and manage meetings on <a target="_blank" rel="noreferrer" href="http://bit.ly/WebInar"> Webinar</a>, Hangouts, GoToWebinar, WebinarJam,YouTube Live, Facebook Live, OBS.<br></br>
             - Educate people with <a target="_blank" rel="noreferrer" href="http://bit.ly/TutoriaL"> video content</a>  to provide them with the deep understanding of SEMrush product.<br></br>
             - Organize workshops and offline events for product education.Manager the YouTube channel and G+ account.Moderate FB and Twitter company accounts.<br></br>
             - Set email marketing tasks for events (invitations, follow-ups, etc.).Collaborate with developers, provided recommendations for the better UX.<br></br>
             - Help users to resolve any issues that they were encountering (Customer Support).
            </p>
          </div>
          <hr></hr>
          <div className="cv-internal-block work-experience">
          <h4>Software Engineer & Technical Support Engineer & Firmware Development & Project Manager at <i>Driven Cards s.r.l.</i> (<a target="_blank" rel="noreferrer" href="https://www.kuennecke.com/en/otto-kuennecke-gmbh-is-glad-to-announce-the-acquisition-of-driven-cards-s-r-l/">Driven Cards s.r.l.</a>)</h4>
          <p className="timeframe">April 2014 – April 2016</p>
          <p>This Company  was integrated by <a target="_blank" rel="noreferrer" href="https://www.kuennecke.com/">Otto Kuennecke Gmbh</a> <br></br>
          Used Visual Studio 2008/2012  C# / php / C++ / PLC / SCADA <br></br>
          - Support IT and Software development in C# IDE Microsoft Visual Studio 2008/2012/2013 - Delphi 7- XE2. <br></br>
          - Integration Vision control for the Laser with a camera. GUI development and customer support.<br></br>
          - Partecipated like SW Engineer in Big Conference "Cartes" with Otto Kunnecke in Paris for two Laser Machine and Big Mailer.<br></br>
          - Project to print with laser on passport and laminator machine for passport - Visual Studio 2013.<br></br> GUI for WINCE 6.0 inside PLC BECKHOFF to pilot automotive machine. Visual Studio 2008.<br></br>
          - Support consultant for  <a target="_blank" rel="noreferrer" href="http://www.bierrebi.com/">BIERREBI ITALIA</a> to develop complex algorithms for graphical improvements and laser cutting, using SVN to work in Team, Visual Studio 2012  <br></br>
          - Printer laser military using RFID system, SmartCard Reader and SCAPS USC1 for marking laser personalization request from customers in Esercito Italiano, Roma. C# & .NET 3.5<br></br>
          - Development of Firmware in embOS - ARM - IAR with LPC2468 to acquire measurement and command about I/O Analogic and Digital <br></br>
          - installation SO for using in mainboard to control the System for Protection Cathodic: End Customer Sonatrach, SAIPEM ENI, METEC, installation in Algeria.<br></br>
          - <a target="_blank" rel="noreferrer" href="/company">Sonatrach - GK 3-Lot 3 Gas Transportation System - Algeria.</a><br></br> 
          - Design and construction of the supervision system (SCADA and RMU) for cathodic protection.
          </p>
          </div>
           <hr></hr>
          <div className="cv-internal-block work-experience">
                <h4>Software Engineer & Technical Support Engineer &  e-Business Resp. at 
                    <i> HSF s.r.l.</i>
                </h4>
          <p className="timeframe">November 2010 – September 2013</p>
          Used Borland Delphi 7/XE2 / C/C++ / XCode <br></br>
          <p>- Administrative business management, contact with customers (mail & FaceToFace & Team Viewer).   <br></br>
          - Creating projects according to the customer's requests with possible prototypes and creating GUI that can let users access to the computer (DELPHI 7)<br></br>
          - Managing Process for the production and developing projects with a particular role in the realization of Software and Firmware,<br></br> programming Pic and Quality Testing, assembly of electronic components on PCBs<br></br>
          - Management as an Admin of the Company WebSite, manage customer's requests coming from the web.<br></br>
          - Head of the talks to anyone who came to the company searching for the work.<br></br>
          - Technical Manager of the Company that is written in its statute according to the Ministerial Decree No. 37/2008<br></br>
          - nEuron UCAV collaboration with UFI Filters<a target="_blank" rel="noreferrer" href="https://ricevuto.ru/images/img-UFI.jpg">UFI Filters </a> <br></br>
          - Developing projects in the following areas Electronics, Automation, Robotics, Aerospace, UAV Prototype - ROV, creating Software especially for electronic boards.<br></br>
          - Carried out and sold projects with DELPHI, which were followed up personally, during the period:<br></br>
          - DataMatrix for INFN - MeterCounter for large ship in telecommunication system, underwater (Teliri and Certamen used from France Telecom) <br></br>
          - Control,communication and driving system of ROV- Data logger for sea Buoys and sea Platforms used in Project NEMO<br></br>
          - <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=EfCOlOyj528">Design and Develop of Autonomous system UAV pilot</a> <br></br>
          - Creating different electronic devices for measuring and test in sector <a target="_blank" rel="noreferrer" href="https://www.coronadiesel.it/thumb.php?width=1200&height=1200&file=uploads%2Fgallery%2Fimages%2F6%2F104.JPG">injectors Piezo, Common Rail and testing for Mitutoyo </a> <br></br>
          - installer Security systemand cameras - alarms - develop an control unit for Aerospace with communication CAN BUS <br></br>
          - Designing a system for detecting data with remote connection with CAN BUS for INGV (NationalInstitute of Geology and Volcanology )<br></br>
          </p>
            <p>
              <i>Video</i>
          </p>
              <p>  <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=EfCOlOyj528">Link Video in Youtube</a></p>
          </div>
          <hr></hr>
          <div className="cv-internal-block work-experience">
          <h4>Researcher, Developer and Stage at <i>(INFN) The Italian Institute for Nuclear Physics - National Laboratories of Sud (LNS )</i>
          (<a target="_blank" rel="noreferrer" href="www.lns.infn.it/">www.lns.infn.it/</a>)
          </h4>
          <p className="timeframe">May 2009 – September 2009</p>
              <p>National public organization for research, supervised by Ministry of Education, University, and Research (MIUR) conducts research activity, theoretical and experimental.<br></br>
              Used VMware for the network to store data and the system was used inside the institute. Installed NetApp FAS2000 and configure it for the internal structure.<br></br>
              The internship was about 300 hours and after this I won a scholarship to study at the same institute.<br></br>
              Thesis Title:
              Development of a control system with graphical user interface for the station of optical amplification for the 
              <a target="_blank" rel="noreferrer" href="https://www.lns.infn.it/en/application/multidisciplinary-submarine-observatories.html"  >  NEMO project</a>
              </p>
          </div>

          <div className="cv-block educations"><h3>Education</h3>
    <hr></hr>
              <div className="cv-internal-block education">
              <p className="timeframe"> January 2011</p><p>Accepted Job position and stopped studying in Faculty of Automation Engineering and Control of Complex Systems, Robotic Systems from University of Catania.</p></div>
              <div className="cv-internal-block education">
              <p className="timeframe"> November 2009</p><p>Bachelor's degree in Faculty of Computer Engineering from University of Catania.</p></div>
              <div className="cv-internal-block education">
              <p className="timeframe">1999 – 2004</p><p>Secondary school  at <i>Liceo Scientifico ”Boggio Lera”</i> – Catania.</p></div>
        </div>
          <div className="cv-block skills">
          <h3>Skills</h3>
          <div className="cv-internal-block technical-skills">
          <h4>Technical Skills</h4>
         <h5>LANGUAGES AND FRAMEWORKS</h5>
          <p>C#/.Net (WPF, Core, Razor/MVC, Windows), React js, TypeScript, C/C++, Bootstrap, PHP, CSS, SASS, JavaScript, jQuery, Microsoft SQL Server, MySQL , XML,  Google Maps APIs.</p>
          <h5>DEVELOPMENT ENVIRONMENTS</h5>
          <p>Microsoft Visual Studio, VS Code, Eclipse, Atom, Arduino IDE.</p>
          <h5>OPERATING SYSTEMS</h5>
          <p>Windows, MacOS, Linux.</p>
          </div>
                    <div className="cv-internal-block linguistic-skills">
                          <h4>Linguistic Skills</h4>
                            <div  className="fixed-width-70">Italian</div><p> Mother tongue</p>
                            <div  className="fixed-width-70">English </div><p>Full professional proficiency</p>
                            <div  className="fixed-width-70">Russian</div><p> Full professional proficiency</p>
                      </div>
          </div>
    <hr></hr>
        <div className="cv-block interests">
          <h3>Interests</h3>
          <p>Sports, family, travelling, cooking, marketing and watch Rugby.</p></div>
          <div className="cv-block voluntary-activities"><h3>Voluntary Activities</h3>
          <p className="timeframe">December 2010 – September 2014</p>
          <p>Vice President & Fundraiser at 
           <a href="https://www.aegee-catania.org/board-precedenti/" rel="noreferrer" target="_blank"><i> AEGEE-Catania </i> </a>
        </p>
 <p>Organizer at  
          <a href="https://www.aegee-catania.org/agora-ct-2017/core-team/" rel="noreferrer" target="_blank"><i>  AGORA Catania 2017</i> </a>
        </p>
        </div>

          <div className="cv-block driving-licenses"><h3>Driving Licenses</h3><p>A,B</p>
          </div>
          </div>
          </div>
          </div>
  <Footer />
    </div>

)

}
