import React from 'react';
import '../../App.css';
import '../Cards.css';
import CardItemPhoto from '../CardItemPhoto';
import Footer from '../Footer';

function Cartes() {
   return (
  <>
    <div className='cards'>
      <h1>Cartes - Paris Event</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
       <ul className='cards__items'>
            <CardItemPhoto
              src='images/a1.JPG'                          
            />
            <CardItemPhoto
              src='images/a4.JPG'           
            />
          </ul>

          <ul className='cards__items'>
            <CardItemPhoto
              src='images/a2.JPG'              
            />
            <CardItemPhoto
              src='images/a3.JPG'            
            />
            <CardItemPhoto
              src='images/a5.JPG'
            />         
          </ul>          
        </div>
      </div> 
    </div> 
<Footer />
</>
  );

}
export default  Cartes;
