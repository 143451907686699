import React from 'react';
import '../../App.css';
import '../Cards.css';
import CardItemSenzaTxt from '../CardItemSenzaTxt';
import CardItemSenzaTxtVert from '../CardItemSenzaTxtVert';
import Footer from '../Footer';


function Weather() {
   return (
  <>
    <div className='cards'>
      <h1>Created by myself, integrated all sensors from different companies to get all excellent values that need!
       </h1>
   <h1>Companies sensors and devices: Vaisala, SMC - Ship Motion Control, Rutter, Advantech, Moxa  etc.   
       </h1>
    <a style={{display: 'flex', justifyContent: 'center'}}  href='http://u1701397.plsk.regruhosting.ru/products/seah-helideck-monitoring-system/' target="_blank"rel="noreferrer">more info </a>
       
      <div className='cards__container'>
        <div className='cards__wrapper'>
       <ul className='cards__items'>
           <CardItemSenzaTxt
              src='images/1.JPG'
              text=''
              label=''
              path='/Weather'
            />            
            <CardItemSenzaTxt
              src='images/2.JPG'
              text=''
              label=''
              path='/Weather'
            />   
          </ul>
<h1>If you are interested to talk about it, just <a href='/contactme' target="_blank"rel="noreferrer">contact me!</a></h1><br></br>
          <ul className='cards__items'>
         
           <CardItemSenzaTxt
               src='images/22.JPG'
              text=''
              label=''
              path='/Weather'
            />    
         <CardItemSenzaTxt
                src='images/33.JPG'
              text=''
              label=''
              path='/Weather'
            />   
          </ul>
        <ul className='cards__items'>
         
           <CardItemSenzaTxtVert
               src='images/3.JPG'
              text=''
              label=''
              path='/Weather'
            />    
         <CardItemSenzaTxtVert
                src='images/4.JPG'
              text=''
              label=''
              path='/Weather'
            />   
        
          </ul>
     <ul className='cards__items'>
         
   <CardItemSenzaTxtVert
               src='images/test.png'
              text=''
              label=''
              path='/Weather'
            />    
         <CardItemSenzaTxtVert
                src='images/6.JPG'
              text=''
              label=''
              path='/Weather'
            />   
          </ul>
        </div>
      </div>  </div>
       <Footer />
    </>

  );

}
export default  Weather;