import React from 'react';
import '../App.css';
import { Button } from './Button';
import { ButtonTrailer } from './ButtonTrailer';

import './HeroSection.css';

function HeroSection() {
  return (
    <div className='hero-container'>
       <div className='me-image-box'>
           <img src='/images/me.jpg'  alt='salvo' />  
      </div>
     {/* <video src='/videos/video-1.mp4' autoPlay loop muted />*/}
      <h1>WORK HISTORY</h1>  {/*CV EXPERIENCE /  */}
      <p>What are you searching for?</p>
      <div className='hero-btns'>
        <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
        >
          GET STARTED
        </Button>
        <ButtonTrailer
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
        //  onClick={()=> window.open('/trailer', "")}
       //   onClick={'/trailer'}
        >
          WATCH FASTER TRAILER <i className='far fa-play-circle' />
        </ButtonTrailer>
      </div>
    </div>
  );
}

export default HeroSection;
