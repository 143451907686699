import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='cards'>
      <h1>Check out ALL these Projects!</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/img-hms.jpg'
              text='Developed All system HMS - Helicopter Monitoring System'
              label='Helicopter'
              path='/projects'
            />
            <CardItem
              src='images/img-radar.jpg'
              text='Integration data from all sensors to UI and possibility to control Radar inside.'
              label='Radome'
              path='/projects'
            />
          </ul>

          <ul className='cards__items'>
            <CardItem
              src='images/img-offshore.jpeg'
              text='Offshore for SAT operation and checking all system installation in Abu Dhabi'
              label='Offshore'
              path='/projects'
            />
            <CardItem
              src='images/img-wea.jpg'
              text='Creation of system for weather and integrated all sensors data in UI'
              label='Weather'
              path='/projects'
            />
            <CardItem
              src='images/img-abb.jpeg'
              text='Collaboration and worked for ABB in project in Abu Dhabi and not only'
              label='ABB'
              path='/company'
            />         
          </ul>

            <ul className='cards__items'>
            <CardItem
              src='images/img-hhi.jpg'
              text='Collaboration with Hyundai Heavy Industries Co. in project in Abu Dhabi'
              label='HHI'
              path='/projects'
            />
            <CardItem
              src='images/img-sonatrach.jpg'
              text='Collaboration with Sonatrach and Saipem in project GK 3 Lot3 in Algeria'
              label='GAS'
              path='/company'
            />
            <CardItem
              src='images/img-cartes.jpg'
              text='Developed Software to print personal data information on Document Card with Laser Machine'
              label='Goverment '
              path='/projects'
            />
            </ul>
               <ul className='cards__items'>
            <CardItem
              src='images/UAVdroneFurgon.JPG'
              text='Developed Software for UAV - Drone '
              label='HSF UAV'
              path='/Drone'
            />
            <CardItem
              src='images/rov1.jpg'
              text='Collaboration for PHOENIX II for UI'
              label='ROV'
              path='/ROV'
            />
            </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
