import React from 'react';
import '../../App.css';
import '../Cards.css';
import CardItemSenzaTxt from '../CardItemSenzaTxt';
//import CardItemSenzaTxtVert from '../CardItemSenzaTxtVert';
import Footer from '../Footer';

function ROV() {
   return (
  <>
    <div className='cards'>
      <h1>Created many parts electronics inside of ROV and UI
       </h1>    
      <div className='cards__container'>
        <div className='cards__wrapper'>
   
       <ul className='cards__items'>
           <CardItemSenzaTxt
              src='images/rov1.jpg'
              text=''
              label=''
              path='/ROV'
            />            
            <CardItemSenzaTxt
              src='images/rov2.jpg'
              text=''
              label=''
              path='/ROV'
            />  
          </ul>
<h1>If you are interested to talk about it, just <a href='/contactme' target="_blank"rel="noreferrer">contact me!</a></h1><br></br>

           <ul className='cards__items'>
             
            <CardItemSenzaTxt
              src='images/rov3.jpg'
              text=''
              label=''
              path='/ROV'
            />   
             <CardItemSenzaTxt
                src='images/rov4.png'
              text=''
              label=''
              path='/ROV'
            />   
          </ul>
        </div>
      </div>  </div>
       <Footer />
    </>

  );

}
export default  ROV;