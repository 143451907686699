import React from 'react';
import '../../App.css';
import { Button } from '../Button';
import { Link } from 'react-router-dom';
import {Linking,Platform,TouchableOpacity,Text} from "react";

function Trailer() {
    return (
      <div className='hero-trailer'>
  {/* <video src='/videos/video-1.mp4' autoPlay loop muted />
     <video width="750" height="500" controls >*/}
      <video    
          id="videoTrailerAuto"      
          src="/videos/trailer.mp4"
          controls
          autoPlay={"autoplay"}
          preload="auto"
          loop
        > </video>
      </div>
    );
}

export default Trailer;

