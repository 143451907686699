import React from 'react';
import './Footer.css';

import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>{/*import { Button } from './Button';   <section className='footer-subscription'>
      </section>*/}
      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>About Me</h2>           
          <Link to='/cv'>CV</Link>
         <Link to={{ pathname:"https://spb.hh.ru/resume/c44a448fff02357a580039ed1f5571456e4b6f" }} 
              target="_blank">CV (CTO / Head of R&D / IT project Manager) in HH.ru</Link>
         <Link to={{ pathname:"https://spb.hh.ru/resume/8c49be7bff09ef0bc20039ed1f436f64654e77" }} 
              target="_blank">CV (Senior Software Engineer / IT Project Manager) in HH.ru</Link>
          </div>
          <div className='footer-link-items'>
            <h2>Contact Me</h2>
            <Link to='/contactme'>Contact</Link>        
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>Videos</h2>
            <Link to='/trailer'>Trailer</Link>
          
          </div>
          <div className='footer-link-items'>
            <h2>Social Media</h2>
            <Link to={{ pathname:"https://www.instagram.com/salvatorericevuto" }} target="_blank">Instagram</Link>
            <Link to={{ pathname:"https://www.facebook.com/salvatore.ricevuto/" }} target="_blank" >Facebook</Link>
            <Link to={{ pathname:"https://vk.com/salvatorericevuto" }} target="_blank" >VK</Link>
            <Link to={{ pathname: "https://it.linkedin.com/pub/salvatore-ricevuto/7b/72/482" }} target="_blank" >Linkedin</Link>
          </div>
        </div>
      </div>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <Link to='/' className='social-logo'>
              ing.SR
             {/* <i class='fab fa-typo3' />*/}
            </Link>
          </div>
          <small className='website-rights'> © 2023</small>
          <div className='social-icons'>
           <Link
              class='social-icon-link instagram'
               to={{ pathname: "https://www.instagram.com/salvatorericevuto" }}
              target='_blank'
              aria-label='Instagram'
            >
              <i className='fab fa-instagram' />
            </Link>
            <Link
              className='social-icon-link facebook'
            to={{ pathname: "https://www.facebook.com/salvatore.ricevuto/"}}
              target='_blank'
              aria-label='Facebook'
            >
              <i className='fab fa-facebook-f' />
            </Link>
         
            <Link
              className='social-icon-link vk'
              to={{ pathname: "https://vk.com/salvatorericevuto" }}
              target='_blank'
              aria-label='VK'
            >
              <i className='fab fa-vk' />
            </Link>
            <Link
              className='social-icon-link twitter'
              to={{ pathname: "https://it.linkedin.com/pub/salvatore-ricevuto/7b/72/482" }}
              target='_blank'
              aria-label='LinkedIn'
            >
              <i className='fab fa-linkedin' />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
