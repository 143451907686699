import React from 'react';
import '../../App.css';
import '../Cards.css';
import CardItem from '../CardItem';
import Footer from '../Footer';


function Company() {
   return (
  <>
    <div className='cards'>
      <h1>All the companies where I have collaborated with or work for</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
       <ul className='cards__items'>
          
            <CardItem
              src='images/img-abb.jpeg'
              text='Collaboration and worked for ABB in project in Abu Dhabi and not only'
              label='ABB'
              path='/ABB'
            />   
             <CardItem
              src='images/img-sonatrach.jpg'
              text='Collaboration with Sonatrach and Saipem in project GK 3 Lot3 in Algeria'
              label='GAS'
              path='/company'
            />
          </ul>

          <ul className='cards__items'>
        
            <CardItem
              src='images/img-UFI.jpg'
              text='Collaboration and worked for UFI Filters in project Neuron UCAV'
              label='UCAV'
              path='/company'
            />   
              <CardItem
              src='images/img-hhi.jpg'
              text='Collaboration with Hyundai Heavy Industries Co. in project in Abu Dhabi'
              label='HHI'
              path='/company'
            />
        
          </ul>
        </div>
      </div>  </div>
       <Footer />
    </>

  );

}
export default  Company;

