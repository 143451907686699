import React from 'react';
import { Link } from 'react-router-dom';

function CardItemSenzaTxtVert(props) {
  return (
    <>
      <li className='cards__item_vert'>
        <Link className='cards__item__link' to={props.path}>
          <figure className='cards__item__pic-wrap-nolabel' data-category={props.label}>
            <img
              className='cards__item__img_vert'
              alt='Travel'
              src={props.src}
            />
          </figure>
        </Link>
      </li>
    </>
  );
}

export default CardItemSenzaTxtVert;
