import React from 'react';
import '../../App.css';
import { Button } from '../Button';
/*import { Link } from 'react-router-dom';*/
import Footer from '../Footer';



function ContactMe() {
  return (

    <div className='footer-container'>{/*import {Linking,Platform,TouchableOpacity,Text} from "react";*/}
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
        Ready to talk more about me and to try opportunities to get some collaboration
        </p>
        <p className='footer-subscription-text'>
          You can write any time, it is for free :)
        </p>
        
            <Button buttonStyle='btn--outline'
                  onClick={() => window.location = 'mailto:salvo@ricevuto.tech'}>
                   WRITE ME
               </Button>    
<p className='footer-subscription-text'> <br></br>
         or You can try to call, it is not free but if you are in Russia, u can!
        </p>
  <Button buttonStyle='btn--outline'
                  onClick={() => window.location = 'tel:+79030979126'}>
                   CALL ME
               </Button>  
<p className='footer-subscription-text'> <br></br>
         or You can try to call, for free!       <i className='fab fa-skype' />
        </p> 
<Button buttonStyle='btn--outline'
                  onClick={() => window.location = 'skype:salvoricevuto'}>
                   SKYPE ME :)  
               </Button> 
      </section>
       <Footer />

    </div>
  );
}
export default ContactMe;
