import React from 'react'
import Navbar from './components/Navbar';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import Projects from './components/pages/Projects';
import Company from './components/pages/Company';
import ContactMe from './components/pages/ContactMe';
import Trailer from './components/pages/Trailer';
import CV from './components/pages/CV';
import ABB from './components/pages/ABB';
import Weather from './components/pages/Weather';
import Drone from './components/pages/Drone';
import ROV from './components/pages/ROV';
import Cartes from './components/pages/Cartes';




import './App.css';
import Home from './components/pages/Home';


function App() {
  return (
<>      
  <Router>
    <Navbar/>
      <Switch>
       
          <Route path='/' exact component={Home} />
          <Route path='/projects' component={Projects} />
          <Route path='/company' component={Company} />
          <Route path='/contactme' component={ContactMe} />
          <Route path='/trailer' component={Trailer} />
          <Route path='/cv' component={CV} />
          <Route path='/ABB' component={ABB} />
          <Route path='/Weather' component={Weather} />
          <Route path='/Drone' component={Drone} />
          <Route path='/ROV' component={ROV} />
          <Route path='/cartes' component={Cartes} />

      </Switch>
  </Router>

  </>      
  );
}

export default App;