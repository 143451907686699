import React from 'react';
import '../../App.css';
import '../Cards.css';
import CardItemSenzaTxt from '../CardItemSenzaTxt';
import CardItemSenzaTxtVert from '../CardItemSenzaTxtVert';
import Footer from '../Footer';


function Drone() {
   return (
  <>
    <div className='cards'>
      <h1>Developed Our UAV Drone in 2010-2013
       </h1>
    <a style={{display: 'flex', justifyContent: 'center'}}  href='http://www.hsfsolutions.com/eng/index.php?option=com_content&view=article&id=141:esempio-prodotto&catid=38:roknewspager-items' target="_blank"rel="noreferrer">more info </a>
       
      <div className='cards__container'>
        <div className='cards__wrapper'>
       <ul className='cards__items'>
           <CardItemSenzaTxt
              src='images/UAVdroneFurgon.JPG'
              text=''
              label=''
              path='/Drone'
            />            
            <CardItemSenzaTxt
              src='images/drone1.JPG'
              text=''
              label=''
              path='/Drone'
            />   
          </ul>
<p>Missione UAV con aereo HSF</p>
<iframe width="500" height="315"
src="https://www.youtube.com/embed/EfCOlOyj528">
</iframe>
<p>Simulation flight with "Draw UAV" throught X-Plane simulator</p>
<iframe width="500" height="315"
src="https://www.youtube.com/embed/vCOQao-oXSk">
</iframe>
<h1>If you are interested to talk about it, just <a href='/contactme' target="_blank"rel="noreferrer">contact me!</a></h1><br></br>

<p>Trailer XHPA - mini AHRS</p>
<iframe width="420" height="315"
src="https://www.youtube.com/embed/F7WwdcBU7k4">
</iframe>
          <ul className='cards__items'>
         
           <CardItemSenzaTxt
               src='images/drone2.jpg'
              text=''
              label=''
              path='/Drone'
            />    
         <CardItemSenzaTxt
                src='images/drone3.jpg'
              text=''
              label=''
              path='/Drone'
            />   
          </ul>
        <ul className='cards__items'>
         
           <CardItemSenzaTxtVert
               src='images/drone4.jpg'
              text=''
              label=''
              path='/Drone'
            />    
         <CardItemSenzaTxtVert
                src='images/drone5.JPG'
              text=''
              label=''
              path='/Drone'
            />   
        
          </ul>
     <ul className='cards__items'>
         
   <CardItemSenzaTxtVert
               src='images/drone6.JPG'
              text=''
              label=''
              path='/Drone'
            />    
         <CardItemSenzaTxtVert
                src='images/drone7.JPG'
              text=''
              label=''
              path='/Drone'
            />   
          </ul>
        </div>
      </div>  </div>
       <Footer />
    </>

  );

}
export default  Drone;